<template>
  <div
    v-if="viewList.length && isCardStyle"
    ref="sideslipImageRef"
    class="sideslip"
    :class="{
      'sideslip__card-bg': isNeedCardBg,
    }"
    :style="[cardStyle, !isNeedCardBg ? { marginTop: 0, marginRight: 0, marginLeft: 0, borderRadius: 0 } : {}]"
  >
    <template v-if="swiperDynamicMap.includes(propData.styleType)">
      <!-- tab 模式展示tab -->
      <s-tab
        v-if="isTabMode && tabTextList.length >= 1"
        v-model="selectedTabIndex"
        :type="'auto'"
        v-bind="tabTextStyle"
        class="sideslip__tab"
        :customStyle="{
          border: 0,
        }"
        @change="handleTabChange"
      >
        <s-tab-item
          v-for="(item, i) in tabTextList"
          :id="i + 1"
          :key="i"
          v-tap="
            getAnalysisData('2-22-2', {
              item: item,
              tab: { tabText: item?.tabText, index: i },
              act_nm: 'click_tab',
              index: i,
              useBffApi: true,
            })
          "
          class="sideslip__tab-item"
        >
          {{ item?.tabText }}
        </s-tab-item>
      </s-tab>
      <!-- 整屏横滑 顶部标题 -->
      <div
        v-else-if="titleStyle.titleText"
        class="sideslip__title"
        :style="{
          textAlign: titleStyle.titlePosition,
          color: titleStyle.titleColor,
        }"
      >
        {{ titleStyle.titleText }}
      </div>
      <VirtualSwiper
        ref="cccSwiperInstance"
        class="sideslip__swiper"
        :options="options"
        :source-data="viewList"
        :initVirtualNum="1"
        :changeActiveIndex="handleSwiperStart"
      >
        <template #default="{ virtualData }">
          <swiper-slide
            v-for="(item, i) in virtualData"
            :key="i"
            class="sideslip__slide"
            :style="sliderItemStyle"
          >
            <SideSlipImage
              :current-page-index="i"
              :data="JSON.parse(JSON.stringify(item)).items"
              :index="index"
              :report-tab="{
                tabText: tabTextList[i]?.tabText,
                index: i,
              }"
              :is-slider="false"
              :prop-data="propData"
              :scene-data="sceneData"
              :cate-links="cateLinks"
              :brand="brand"
              :is-need-card-bg="isNeedCardBg"
              :extend-data="extendData"
              :language="language"
              :padding="!isNeedCardBg ? cardStyle?.margin : ''"
              @baseImageMounted="baseImageMounted()"
            />
          </swiper-slide>
        </template>
      </VirtualSwiper>
      <!-- 普通模式 展示pagination -->
      <div
        v-if="viewList.length > 1 && !isTabMode"
        class="sideslip__pagination"
      >
        <div
          class="sideslip__pagination-area"
          :style="{ width: `${12 * viewList.length}px` }"
        >
          <i
            class="sideslip__pagination-active"
            :style="{
              transform: `translateX(${100 * swiperIndex}%)`,
            }"
          ></i>
        </div>
      </div>
    </template>
  </div>
  <!-- 切换静态组件 -->
  <SideSlipImageStatic
    v-else
    v-bind="$props"
    @baseImageMounted="baseImageMounted()"
  />
</template>

<script>
import { STabItem } from '@shein-aidc/sui-tab-item/mobile'
import { STab } from '@shein-aidc/sui-tab/mobile'
import { tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick } from 'vue'
import SideSlipImageStatic from 'public/src/pages/components/ccc/components/sideslip-image/Index.vue'
import SideSlipImage from './SideSlipImage.vue'
import VirtualSwiper from 'public/src/pages/components/ccc/base/swiper/VirtualSwiper.vue'

import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixin from '../../components/mixins/mixin'

import { register } from 'swiper/element'
typeof window !== 'undefined' && register()

// 静态横滑 4、5 图
const staticCompStyleType = [
  'ITEM_IMAGE_FOUR_COLS_CAROUSEL',
  'ITEM_IMAGE_FIVE_COLS_CAROUSEL', 
]
// 动态横滑多图
const dynamicCompStyleType = [
  'MULTI_ITEMS_CAROUSEL_TABS_DYNAMIC',
  'MULTI_ITEMS_CAROUSEL_IND_DYNAMIC',
  'MULTI_ITEMS_CAROUSEL_CON_DYNAMIC',
]

const swiperDynamicMap =  [
  ...staticCompStyleType,
  ...dynamicCompStyleType,
]

export default defineComponent({
  name: 'SideSlipTabsCompose',
  directives: {
    tap,
  },
  components: {
    SideSlipImage,
    STab,
    STabItem,
    SideSlipImageStatic,
    VirtualSwiper
  },
  mixins: [mixin],
  emits: ['baseImageMounted'],
  props: {
    ...commonProps,
    // 静态横滑组件是否命中卡片化 abt
    isCardAbt: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      isFirstPage: this.propData?.isFirstPage,
    }
  },
  data() {
    const {
      props: {
        metaData: {
          tabSelectedColor = '#222222',
          tabSelectColor = '#767676',
          tabBgColor = 'white',
          showConvertScreenTab = '0',
          titleColor = '#222222',
          titlePosition = 'left',
          titleText = '',
          backgroundColor = '#ffffff',
          backgroundImg = {},
          isPromiseSlide = '0'
        },
      },
    } = this.propData || {}
    const extendData = this.sceneData?.extendData || this.context?.content?.extendParse || {}
    return {
      isTabMode: showConvertScreenTab == '1', // Tab模式
      titleStyle: {
        titleColor,
        titlePosition,
        titleText,
      },
      tabSelectedColor,
      tabSelectColor,
      tabBgColor,
      // 初始Swiper配置
      options: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 8,
        virtualEnable: true, // 启用虚拟slides
        observer: true, // 修改swiper自己或子元素时，自动初始化swipe
        injectStyles: [
        `.swiper-horizontal {
            overflow : visible;
          }`
        ]
      },
      swiperDynamicMap,
      // swiperIndex从0开始
      swiperIndex: 0,
      // selectedTabIndex从1开始
      selectedTabIndex: 1,
      extendData, // 页面全局配置
      backgroundColor,
      backgroundImg,
      isPromiseSlide: isPromiseSlide == '1'
    }
  },
  computed: {
    tabTextStyle() {
      return {
        'selected-color': this.tabSelectedColor,
        'un-selected-color': this.tabSelectColor,
        // 静态组件卡片化或动态 tab背景色一定透明
        style: {
          background: (this.isStaticCardStyle || dynamicCompStyleType.includes(this.propData.styleType)) ? this.backgroundColor : this.tabBgColor,
        }
      }
    },
    viewList() {
      const items = []
      const { slides } = this.propData.props?.metaData
      const { items: propItems } = this.propData.props
      // this.propData.props.items 按照 slides 的个数按照顺序进行分组
      if (Array.isArray(propItems)) {
        const perList = Math.ceil(propItems.length / slides)
        for (let i = 0; i < slides; i++) {
          items[i] = {
            items: propItems.slice(i * perList, (i + 1) * perList),
            tabText: propItems.slice(i * perList, (i + 1) * perList)?.[0]?.tabText,
            markMap: propItems.slice(i * perList, (i + 1) * perList)?.[0]?.markMap // 添加markMap字段，埋点上报第一条数据
          }
        }
      }
      return items
    },
    tabTextList() {
      // 添加markMap字段，埋点上报
      return this.isTabMode
        ? this.viewList.map(tab => ({
        tabText: tab?.tabText || '',
        markMap: tab?.markMap || {},
      }))
        : []
    },
    // 静态组件卡片化通过 abt 控制
    isStaticCardStyle() {
      return this.isCardAbt 
    },
    isCardStyle() {
      if (staticCompStyleType.includes(this.propData.styleType)) {
        return this.isStaticCardStyle
      }
      if (dynamicCompStyleType.includes(this.propData.styleType)) {
        return this.isDynamicCardStyle
      }

      return false
    },
    // 动态组件卡片组件接全局配置: 卡片化字段为false，或者边距、圆角 这2项都获取失败或为空时，代表非卡片化样式，展示原有样式
    isDynamicCardStyle() {
      const {
        isCardShow = false,
        margin: marginList = [],
        cardRadius = 0,
      } = this.propData?.props?.metaData || {}
      if(!isCardShow) return false
      if (isCardShow || cardRadius > 0) return true
      return Array.isArray(marginList) && marginList.some((item) => item > 0)
    },
    hadSmallTitle() {
      return this.viewList.some((item) => {
        if (!Array.isArray(item?.items)) return false
        return item?.items.some((i) => i.smallTitle)
      })
    },
    isStaticFiveImage() {
      return this.propData.styleType === 'ITEM_IMAGE_FIVE_COLS_CAROUSEL'
    },
    isNeedCardBg() {
      if (staticCompStyleType.includes(this.propData.styleType)) {
        // 无标题不需要卡片底衬
        if (this.isStaticCardStyle && this.hadSmallTitle) return true
        return this.isTabMode || false
      }

      // 配置标题、tab、文本相关则需要卡片背景
      // slide和swiper的viewList结构区分
      if (!this.swiperDynamicMap.includes(this.propData.styleType)) return false
      return Boolean(
        this.isTabMode || this.hadSmallTitle || this.titleStyle?.titleText
      )
    },
    cardStyle() {
      // 1.如果全局配置中有卡片化配置，则动态组件使用全局配置中的卡片化配置
      // 2.如果全局配置中有卡片化配置，则静态组件命中abt时使用全局配置中的卡片化配置
      // 3.如果全局配置中没有卡片化配置，则静态组件命中abt时使用默认的兜底卡片化配置 cardRadius=3, margin=[0, 8, 10, 8]

      // 静态组件
      if (staticCompStyleType.includes(this.propData.styleType)) {
        const defaultMargin = [0, 8, 10, 8]
        const defaultCardRadius = 3

        const { isCardShow = false, margin = [], cardRadius = 0 } = this.propData?.props?.metaData || {}
        const marginList =
          isCardShow && Array.isArray(margin) && margin.length === 4
            ? margin.slice()
            : defaultMargin
        const marginStyle = marginList
          .map((item) => `${Number(item)}px`)
          .join(' ')
        const styleResult = {
          margin: marginStyle,
          borderRadius: isCardShow ? `${cardRadius}px` : `${defaultCardRadius}px`,
        }
        if((this.hadSmallTitle || (this.isTabMode && this.viewList?.length))) {
          styleResult.backgroundColor = this.backgroundColor
        }
        // 静态 5 图片卡片化且无极横滑样式右侧贴边
        if (this.isStaticFiveImage && this.isPromiseSlide) {
          styleResult.marginRight = 0
        }
        return styleResult
      }
      // 动态组件
      if (dynamicCompStyleType.includes(this.propData.styleType)) {
        const { margin = [], cardRadius = 0 } = this.propData?.props?.metaData || {}
        const marginList =
          Array.isArray(margin) && margin.length === 4
            ? margin.slice()
            : Array(4).fill(0)
        const marginStyle = marginList
          .map((item) => `${Number(item)}px`)
          .join(' ')
        const styleResult = {
          margin: marginStyle
        }
        if (this.isNeedCardBg) {
          styleResult.borderRadius = `${Number(cardRadius)}px`
          // 新加背景色/图
          styleResult.backgroundColor = this.backgroundColor
          styleResult.backgroundSize = '100% 100%'
          styleResult.backgroundImage = `url(${ this.backgroundImg?.src })`
        }
        return styleResult
      }
     
      return {}
    },
    sliderItemStyle(){
      const { spaceBetween = 8 } = this.options
      const style = {}
      const gap = (Number(spaceBetween) ?? 8) + 'px'
      const { GB_cssRight = false } = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo
      style.marginLeft = GB_cssRight ? gap : 'initial'
      style.marginRight = GB_cssRight ? 'initial' : gap
      return style
    },
  },
  mounted() {
    // this.initSwiper()
    this.handleSlslog()
  },
  activated() {
    if (this.selectedTabIndex) {
      this.handleTabChange({ val: this.selectedTabIndex })
    }
  },
  methods: {
    // 初始化 swiper
    initSwiper() {
      // swiper element
      const swiperEl = this.$refs.cccSwiperInstance
      if (!swiperEl) return
      // swiper parameters
      const swiperParams = {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 8,
        observer: true, // 修改swiper自己或子元素时，自动初始化swipe
        injectStyles: [
        `
          .swiper-horizontal {
            overflow : visible;
          }
        `
        ],
      }
      
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
    },
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    handleSwiperStart(swiper) {
      const { GB_cssRight } = gbCommonInfo
      let activeIndex = swiper?.activeIndex ?? 0
      this.selectedTabIndex = activeIndex + 1
      this.swiperIndex = GB_cssRight ? -activeIndex : activeIndex
    },
    handleTabChange({ val }) {
      this.$refs?.cccSwiperInstance?.swiperInstance?.slideTo(val - 1)
      nextTick(() => {
        this.$refs.cccSwiperInstance?.updateVirtualSwiperInstance()
      })
    },
    handleSlslog() {
      if(this.isCardStyle) {
        // 无数据异常上报
        if(!this.viewList.length) {
          this.hideCompSls({
            logLevel: 3,
            tag: 'web_client_home_error_total',
            message: 'CCCShownFilterError',
            reason: 'itemsEmpty',
          })
        } else {
          this.viewList.map(viewItem => {
            viewItem?.items?.map(item => {
              const fieldNames = ['height', 'width', 'src', 'ratio']
              for (const key in fieldNames) {
                if (Object.hasOwnProperty.call(fieldNames, key)) {
                  const element = fieldNames[key];
                  // 图片异常上报
                  if(!item.image[element]) {
                    this.hideCompSls({
                      logLevel: 3,
                      tag: 'web_client_home_error_total',
                      message: 'CCCDataError',
                      reason: 'itemImageError',
                      extraInfo: {
                        hrefTarget: item.hrefTarget,
                        hrefType: item.hrefType,
                        missField: element,
                      },
                    })
                    break;
                  }
                }
              }
            });
          })
        }
      }
    }
  },
})
</script>

<style lang="less" scoped>
.sideslip {
  overflow: hidden;
  
  &__tab {
    height: 1rem;
    :deep(.sui-tab-item-mobile) {
      margin-top: -0.16rem;
    }
    :deep (.sui-tab-mobile__line) {
      bottom: 11px;
    }
  }
  &__swiper {
    display: flex;
    flex-wrap: nowrap;
  }
  &__slide {
    --slides-per-view: 1;
    width: calc(100% / var(--slides-per-view));
    flex-shrink: 0;
  }

  &__pagination {
    padding: 4px 0 0;
  }

  &__pagination-area {
    margin: 0 auto;
    width: 24px;
    position: relative;
    background-color: #e5e5e5;
    overflow: hidden;
  }

  &__pagination-active {
    display: block;
    height: 3px;
    width: 12px;
    background-color: #222;
    transition: transform 0.2s;
  }

  .sideslip-placeholder {
    width: 100%;
    height: 12px;
  }

  &__card-bg {
    padding: 4px 0;
    background-color: #fff;

    .sideslip__tab {
      margin-top: -4px;
    }

    .sideslip__swiper {
      padding: 0 4px;

      :deep(.swiper-wrapper) {
        will-change: transform; // 兼容低端安卓机白边问题
      }
    }
    
    .sideslip__title {
      margin: 4px 0 8px;
      padding: 0 6px;
      width: 100%;
      font-size: 13px;
      line-height: 16px;
      font-weight: 700;
      .line-camp(1);
    }
  }

  :deep(.base-img__href) {
    margin-bottom: -1px;  // 1px以防露出间隙、黑边
  }
}
</style>
